export const SERVER = {
  WEST: "Albion West",
  EAST: "Albion East",
};

export const SERVERS = [SERVER.WEST, SERVER.EAST];

export const LANGUAGES = ["en", "pt", "es", "fr", "ru"];

export const KILL_MODES = ["image", "text"];

export const RANKING_MODES = ["off", "hourly", "daily"];

export const SUBSCRIPTION_STATUSES = ["Free", "Active", "Expired"];

export enum TRACK_TYPE {
  PLAYERS = "players",
  GUILDS = "guilds",
  ALLIANCES = "alliances",
}
